import React from 'react';
import './NotFoundInfo.scss';
import { useHistory } from 'react-router-dom';

const NotFoundInfo = () => {
  const history = useHistory();

  history.goBack();

  return (
    <div className='NotFoundInfo'>
      <div className='not-found-illust'>
        <img src={'/images/NotFound/404_school_illust.png'} alt='illust' />
      </div>
      <div className='not-found-headline'>
        <p>{'죄송합니다. 현재 찾을 수 없는 페이지를 요청하셨습니다.'}</p>
      </div>
      <div className='not-found-subline'>
        <p>{'존재하지 않는 주소를 입력하셨거나,'}</p>
        <p>{'요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.'}</p>
        <p>{'궁금하신 점이 있으시면 언제든 고객센터를 통해 문의해 주시기 바랍니다.'}</p>
      </div>
      <div className='not-found-btns'>
        <button className='not-found-button' onClick={() => history.push('/')}>
          메인으로
        </button>
        <button className='not-found-button' onClick={() => history.goBack()}>
          이전 페이지
        </button>
      </div>
    </div>
  );
};

export default NotFoundInfo;
