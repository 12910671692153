import React from 'react';
type TemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

export const Template = ({ header, children, footer }: TemplateProps) => {
  return (
    <div className='Template'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};
