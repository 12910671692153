import React from 'react';
import './SchoolDownloadSpec.scss';

const SchoolDownloadSpec = () => {
  return (
    <div className='SchoolDownloadSpec'>
      <div className='download-headline'>
        <p>Specifications</p>
      </div>
      <div className='download-spec-table-wrap'>
        <DownloadSpecTable>
          <DownloadSpecHead />
          <tbody>
            <DownloadSpecCPU />
            <DownloadSpecRAM />
            <DownloadSpecVGA />
            <DownloadSpecHDD />
            <DownloadSpecOS />
          </tbody>
        </DownloadSpecTable>
      </div>
    </div>
  );
};

const DownloadSpecTable = ({ children }: { children: React.ReactNode }) => {
  return <table className='download-spec-table'>{children}</table>;
};

const DownloadSpecHead = () => {
  return (
    <thead>
      <tr className='download-spec-head'>
        <th className='download-col-first'>Sortation</th>
        <th className='download-col-rest' colSpan={2}>
          Minimum
        </th>
        {/* <td>PLUS</td> */}
        <th className='download-col-rest'>Recommended</th>
      </tr>
    </thead>
  );
};

const DownloadSpecCPU = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>Processor(CPU)</td>
      <td className='download-col-rest' colSpan={2}>
        Intel® Core™ i3 or AMD Phenom™ X3 8650
      </td>
      <td className='download-col-rest'>Intel® Core™ i5 or AMD Phenom™ Ⅱ X3 or higher</td>
    </tr>
  );
};

const DownloadSpecRAM = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>Memory(RAM)</td>
      <td className='download-col-rest' colSpan={2}>
        4 GB RAM
      </td>
      {/* <td>10,000원</td> */}
      <td className='download-col-rest'>6 GB RAM</td>
    </tr>
  );
};

const DownloadSpecVGA = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>Graphic(VGA)</td>
      <td className='download-col-rest' colSpan={2}>
        NVIDIA® GeForce® GTX 460, ATI Radeon™ HD 4850, or Intel® HD Graphics 4400
      </td>
      {/* <td>100,000원</td> */}
      <td className='download-col-rest'>NVIDIA® GeForce® GTX 660 or AMD Radeon™ HD 7950 or higher</td>
    </tr>
  );
};

const DownloadSpecHDD = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>Hard Disk(HDD)</td>
      <td className='download-col-rest' colSpan={3}>
        30 GB or higher free memory space.
      </td>
    </tr>
  );
};

const DownloadSpecOS = () => {
  return (
    <tr className='download-spec-info'>
      <td className='download-col-first'>Operation System(OS)</td>
      <td className='download-col-rest' colSpan={3}>
        Windows® 7 / Windows® 8 / Windows® 10 64-bit
      </td>
    </tr>
  );
};

export default SchoolDownloadSpec;
