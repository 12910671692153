import React from 'react';
import './MyPageTemplate.scss';
type MyPageTemplateProps = {
  header: JSX.Element;
  children: React.ReactNode;
  footer: JSX.Element;
};

const MyPageTemplate = ({ header, children, footer }: MyPageTemplateProps) => {
  return (
    <div className='MyPageTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default MyPageTemplate;
