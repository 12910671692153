import React from "react";
import { search } from "images/ShareMap";
import "./InputSearchBox.scss";

const InputSearchBox = ({
  handleChangeSearchQuery,
  handleClickSearch,
  handleKeyPressEnterSearch,
  ...rest
}) => {
  return (
    <div className="input-search-box">
      <input
        type="text"
        onChange={e => handleChangeSearchQuery(e.target.value)}
        onKeyPress={handleKeyPressEnterSearch}
        {...rest}
      />
      <button onClick={handleClickSearch}>
        <img src={search} alt="search" />
      </button>
    </div>
  );
};

export default InputSearchBox;
