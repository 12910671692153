import React from 'react';
import './SchoolIntroVideo.scss';
import ReactPlayer from 'react-player';

type SchoolIntroVideoProps = {
  isShowVideo: boolean;
  off(): void;
};

const SchoolIntroVideo = ({ isShowVideo, off }: SchoolIntroVideoProps) => {
  if (!isShowVideo) return null;
  return (
    <div className='SchoolIntroVideo' onClick={off}>
      <div className='SchoolIntroVideo-inner'>
        {/* <iframe src="https://www.youtube.com/embed/gEllR0SEjhQ" frameborder="0" /> */}
        <ReactPlayer url='https://www.youtube.com/watch?v=MBlzdzaE8ak' width='100%' height='100%' controls={true} />
      </div>
    </div>
  );
};

export default SchoolIntroVideo;
