import React, { useState } from 'react';
import { LicenseCodeUsedList } from './LicenseCodeUsedList';
import { LicenseCodeUsedTitle } from './LicenseCodeUsedTitle';
import { LicenseCodeUsedTerm } from './LicenseCodeUsedTerm';
import './LicenseCodeUsed.scss';

type LicenseCodeUsedProps = {
  email?: string;
  showList: string;
};

const LicenseCodeUsed = ({ email, showList }: LicenseCodeUsedProps) => {
  const [lookupPeriod, setLookupPeriod] = useState(3);
  if (showList !== 'license') return null;

  const onClickLookupPeriod = (_lookupPeriod: number) => {
    setLookupPeriod(_lookupPeriod);
  };

  return (
    <div className='LicenseCodeUsed'>
      <LicenseCodeUsedTitle />
      <LicenseCodeUsedTerm lookupPeriod={lookupPeriod} onClickLookupPeriod={onClickLookupPeriod} />
      <LicenseCodeUsedList email={email} lookupPeriod={lookupPeriod} />
    </div>
  );
};

export default LicenseCodeUsed;
