import React from 'react';
import { Popup } from 'components/common/Popup';
import './ShareMapDetail.scss';
import useShareMapDetail from 'hooks/shareMap/useShareMapDetail';

const ShareMapDetail = () => {
  const { shareMapDetail, isShow, off } = useShareMapDetail();

  if (!isShow) return null;

  return (
    <div className='ShareMapDetail'>
      <Popup>
        <div className='share-map-detail-popup'>
          <div className='share-map-detail-title'>
            {shareMapDetail?.title}
            <div className='detail-close-box'>
              <span className='close rounded' onClick={off} />
            </div>
          </div>
          <div className='share-map-detail-thumb' style={{ backgroundImage: `url(${shareMapDetail?.imgThumbUrl})` }} />
          <div className='share-map-detail-explain'>{shareMapDetail?.desc}</div>
        </div>
      </Popup>
    </div>
  );
};

export default ShareMapDetail;
