import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function useLocationQuery<T>() {
  const location = useLocation();

  const query = (queryString.parse(location.search) as unknown) as T;

  return query;
}
