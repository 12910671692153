import React from 'react';
import './NoLicenseList.scss';
import { no_license } from 'images/MyPage';
const NoLicenseList = () => {
  return (
    <div className='NoLicenseList'>
      <img src={no_license} alt='no_license' />
      <p>You don't have a license in use!</p>
      <p>Please purchase a new license.</p>
      <a href='https://school.vrware.us/pricing'>
        <button>Go to buy a license</button>
      </a>
    </div>
  );
};

export default NoLicenseList;
