import { Footer } from 'components/base/Footer';
import { Header } from 'components/base/Header';
import { Template } from 'components/base/Template/Template';
import { Banner } from 'components/common/Banner';
import { IntroContents } from 'components/intro/IntroContents/IntroContents';
import { IntroTop } from 'components/intro/IntroTop/IntroTop';
import { useAtom } from 'jotai';
import { bgColorAtom } from 'lib/common/atoms';
import React, { useEffect } from 'react';

export const IntroPage = () => {
  const [bgColor, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    setBgColor('#00aa47');
  }, [setBgColor]);

  return (
    <Template header={<Header />} footer={<Footer />}>
      <Banner illust={'/images/intro/Image1.png'} bannerText={'GENIVRWARE'} bgColor={bgColor} />
      <div className='container'>
        <IntroTop />
        <IntroContents />
      </div>
    </Template>
  );
};
