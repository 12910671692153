import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/promise';
import 'core-js/es';

ReactDOM.render(<Root />, document.getElementById('root'));
