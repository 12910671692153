import React from 'react';
import './HeaderNotLogin.scss';
// import { useLocation } from 'react-router-dom';

const HeaderNotLogin = () => {
  // let location = useLocation();
  let loginUrl;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'http://localhost:3000';
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }

  return (
    <ul className='HeaderNotLogin'>
      <li>
        {/* <a href={`${loginUrl}/join?redirectUrl=${redirectUrl}`}> */}
        <a href={`${loginUrl}/join?hl=en`}>
          <button className='login-button'>SIGN UP</button>
        </a>
      </li>
      <li>
        {/* <a href={`${loginUrl}/login?redirectUrl=${redirectUrl}`}> */}
        <a href={`${loginUrl}/login?hl=en`}>
          <button className='login-button'>LOGIN</button>
        </a>
      </li>
    </ul>
  );
};

export default HeaderNotLogin;
