import React, { useEffect } from 'react';
import './HeaderMobileMenu.scss';
import { bgColorAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';

type HeaderMobileMenuProps = {
  isSession?: boolean;
  onClickLogout(): Promise<void>;
  onClickGoToPath(path: string): void;
};

const HeaderMobileMenu = ({ isSession, onClickGoToPath, onClickLogout }: HeaderMobileMenuProps) => {
  const [bgColor] = useAtom(bgColorAtom);

  let loginUrl;

  if (process.env.NODE_ENV === 'development') {
    loginUrl = 'http://localhost:3000';
    // redirectUrl = 'http://localhost:3001' + location.pathname;
  } else if (process.env.NODE_ENV === 'production') {
    loginUrl = 'https://my.vrware.us';
    // redirectUrl = 'https://school.vrware.us' + location.pathname;
  }

  useEffect(() => {
    function onClickToggle() {
      document.querySelector('.menu-trigger')?.classList.toggle('icon-active');
      document.querySelector('.links')?.classList.toggle('toggle');
      document.querySelector('.cotton')?.classList.toggle('cotton-toggle');
    }

    document.getElementById('menu-trigger')?.addEventListener('click', onClickToggle);
    document.getElementById('cotton')?.addEventListener('click', onClickToggle);

    return () => {
      document.getElementById('menu-trigger')?.removeEventListener('click', onClickToggle);
      document.getElementById('cotton')?.removeEventListener('click', onClickToggle);
    };
  }, []);

  return (
    <div className='HeaderMobileMenu'>
      <div className='wrapper'>
        <div className='menu-trigger' id='menu-trigger'>
          <span />
          <span />
          <span />
          <span />
        </div>
        <div className='links' style={{ background: bgColor }}>
          <ul>
            <li onClick={() => onClickGoToPath('/intro')}>GENIVRWARE</li>
            <li onClick={() => onClickGoToPath('/community/share-map')}>SHARED MAPS</li>
            {/* 원래 커뮤니티임 */}
            <li onClick={() => onClickGoToPath('/download')}>DOWNLOAD</li>
            <li onClick={() => onClickGoToPath('/manual')}>MANUAL</li>
            {!isSession ? (
              <div>
                <li>
                  <a href={`${loginUrl}/join?hl=en`}>SIGN UP</a>
                  {/* <a href={`${loginUrl}/join?redirectUrl=${redirectUrl}`}>회원가입</a> */}
                </li>
                <li>
                  {/* <a href={`${loginUrl}/login?redirectUrl=${redirectUrl}`}>로그인</a> */}
                  <a href={`${loginUrl}/login?hl=en`}>LOGIN</a>
                </li>
              </div>
            ) : (
              <div>
                <li onClick={() => onClickGoToPath('/mypage')}>MYPAGE</li>
                <li onClick={onClickLogout}>LOGOUT</li>
              </div>
            )}
          </ul>
          <div className='cotton' id='cotton' />
        </div>
      </div>
    </div>
  );
};

export default HeaderMobileMenu;
