import React from 'react';
import './ShareMapTemplate.scss';

type ShareMapTemplateProps = {
  header: JSX.Element;
  children: React.ReactNode;
  footer: JSX.Element;
};

const ShareMapTemplate = ({ header, children, footer }: ShareMapTemplateProps) => {
  return (
    <div className='ShareMapTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default ShareMapTemplate;
