import React, { useEffect } from 'react';
import AOS from 'aos';
import { MainTemplate } from '../components/main/MainTemplate';
import { Header } from '../components/base/Header';
import { MainBanner } from '../components/main/MainBanner';
import { SchoolIntroVideo } from '../components/main/SchoolIntroVideo';
import { Footer } from '../components/base/Footer';
import useShowVideo from '../hooks/main/useShowVideo';
import { bgColorAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';

const Main = () => {
  const { isShowVideo, on, off } = useShowVideo();
  const [, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    AOS.init({ duration: 1200 });
    return () => {
      AOS.refresh();
    };
  }, []);

  useEffect(() => {
    setBgColor('#00b9ef');
  }, [setBgColor]);

  return (
    <MainTemplate header={<Header />} footer={<Footer />}>
      <SchoolIntroVideo isShowVideo={isShowVideo} off={off} />
      <MainBanner on={on} />
    </MainTemplate>
  );
};

export default Main;
