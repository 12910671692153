import React, { useCallback, useEffect, useState } from 'react';
import { MyPageTemplate } from '../components/myPage/MyPageTemplate';
import { MyPageTop } from '../components/myPage/MyPageTop';
import { MyPageList } from '../components/myPage/MyPageList';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import LicenseCodeUsed from '../components/myPage/LicenseCodeUsed';
import { LicenseCode } from '../components/myPage/LicenseCode';
import useSession from '../hooks/useSession';
import { MyLicenseList } from '../components/myPage/MyLicenseList';
import useMember from 'hooks/useMember';
import { useParams, useHistory } from 'react-router-dom';
import { SchoolApi, VrwareApi } from 'lib/api';
import { LicenseInfo, MyStudents } from 'types';
import { bgColorAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';

const MyPagePage = () => {
  const { isSession } = useSession();
  const { memberInfo, email } = useMember();
  let { showList } = useParams<{ showList: string }>();
  const history = useHistory();
  const [licenseList, setLicenseList] = useState<LicenseInfo[]>([]);
  const [myStudentList, setMyStudentList] = useState<MyStudents[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bgColor, setBgColor] = useAtom(bgColorAtom);

  const loadLicenseList = useCallback(async () => {
    if (!email) return;

    try {
      const res = await VrwareApi(`/v1.1/licenses?email=${email}&licenseType=school`);
      setLicenseList(res.data.data.licenseList);
      setIsLoading(false);
    } catch (error) {}
  }, [email]);

  const loadMyStudentList = useCallback(async () => {
    if (!email) return;

    try {
      const res = await SchoolApi(`/v1.0/my-students/${email}`);
      setMyStudentList(res.data.data.myStudentList);
    } catch (error) {}
  }, [email]);

  useEffect(() => {
    setBgColor('#00b9ef');
  }, [setBgColor]);

  useEffect(() => {
    loadLicenseList();
    loadMyStudentList();
  }, [loadLicenseList, loadMyStudentList]);

  useEffect(() => {
    if (!isSession) {
      history.push('/');
      return alert('Please log in and use it.');
    }
    if (isSession && !showList) history.push('/mypage/license');
  }, [history, isSession, showList]);

  return (
    <MyPageTemplate header={<Header />} footer={<Footer />}>
      <MyPageTop nickname={memberInfo?.nickname} bgColor={bgColor} />
      <div className='container'>
        <MyPageList licenseList={licenseList} myStudentList={myStudentList} />
        <MyLicenseList email={email} showList={showList} licenseList={licenseList} isLoading={isLoading} />
        <LicenseCode email={email} showList={showList} />
        <LicenseCodeUsed email={email} showList={showList} />
      </div>
    </MyPageTemplate>
  );
};

export default MyPagePage;
