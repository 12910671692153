import React from 'react';
import './SelectSearchBox.scss';

const SelectSearchBox = ({ options, handleChangeSearchOption }) => {
  const optionsList = options.map((options) => (
    <option key={options.key} value={options.option}>
      {options.option}
    </option>
  ));

  return (
    <div className='select-search-box'>
      <select onChange={(e) => handleChangeSearchOption(e.target.value)}>{optionsList}</select>
      <div className='select__arrow' />
    </div>
  );
};

export default SelectSearchBox;
