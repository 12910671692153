import useSWR, { mutate } from 'swr';
import { MemberInfo } from '../types';

const KEY = 'members';

export default function useMember() {
  const { data: memberInfo, mutate } = useSWR<MemberInfo>(KEY);

  const setMemberInfo = (memberInfo?: MemberInfo) => {
    mutate(memberInfo);
  };

  return {
    memberInfo,
    email: memberInfo?.email,
    setMemberInfo,
  };
}

export const setMemberInfo = (memberInfo?: MemberInfo) => {
  mutate(KEY, memberInfo);
};
