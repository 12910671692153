import React from 'react';
import './MyPageList.scss';
import { NavLink } from 'react-router-dom';

import { LicenseInfo, MyStudents } from 'types';

type MyPageListProps = {
  licenseList: LicenseInfo[];
  myStudentList: MyStudents[];
};

const MyPageList = ({ licenseList, myStudentList }: MyPageListProps) => {
  return (
    <div className='MyPageList'>
      <div className='mypage-list-select'>
        <NavLink to={'/mypage/license'} className='mypage-select' activeClassName='select-active'>
          View License History
        </NavLink>
      </div>
    </div>
  );
};

export default MyPageList;
