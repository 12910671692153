import React from 'react';
import './MainTemplate.scss';

type MainTemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

const MainTemplate = ({ header, children, footer }: MainTemplateProps) => {
  return (
    <div className='MainTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default MainTemplate;
