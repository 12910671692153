import React from 'react';
import { option } from 'images/MyPage';
import './MyPageTop.scss';

let updateLink: string;

if (process.env.NODE_ENV === 'development') {
  updateLink = `http://localhost:3000/mypage/update`;
  // updateLink = `http://localhost:3000/mypage/update?redirectUrl=http://localhost:3001${location.pathname}`;
} else if (process.env.NODE_ENV === 'production') {
  // updateLink = `https://my.vrware.us/mypage/update?redirectUrl=https://school.vrware.us${location.pathname}`;
  updateLink = `https://my.vrware.us/mypage/update?hl=en`;
}

type MyPageTopProps = {
  nickname?: string;
  bgColor?: string;
};

const MyPageTop = ({ nickname, bgColor }: MyPageTopProps) => {
  return (
    <div className='MyPageTop' style={{ backgroundColor: bgColor }}>
      <div className='user-info'>
        <label>Hello</label>
        <div>
          <span>{`${nickname || ''}`}</span>
          <div className='user-option'>
            <a href={updateLink} target='_sub'>
              <div className='user-update'>
                <span>correction</span>
                <img src={option} alt='option' />
              </div>
            </a>
          </div>
        </div>
        <div className='mypage-top-right'>
          <img src={'/images/myPage/Image1.png'} alt='mypage-illust' />
        </div>
      </div>
    </div>
  );
};

export default MyPageTop;
