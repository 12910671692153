import React from 'react';
import './MyLicenseInfo.scss';
import { dateFormat } from '../../../lib/common/dateFormat';
import { LicenseInfo } from 'types';

type MyLicenseInfoProps = {
  licenseInfo: LicenseInfo;
};

const MyLicenseInfo = ({ licenseInfo }: MyLicenseInfoProps) => {
  return (
    <div className='MyLicenseInfo'>
      <div className='my-license-name'>
        {/* VRWARE {capitalizeFirstLetter(licenseInfo.type)} {capitalizeFirstLetter(licenseInfo.licenseGrade)} */}
        GENIVRWARE
      </div>
      <div className='my-license-info'>
        <p className='current-license-state'>
          {/* You are now using {capitalizeFirstLetter(licenseInfo.type)} {capitalizeFirstLetter(licenseInfo.licenseGrade)} */}
          You are now using GENIVRWARE.
          {/* {expireCompare(licenseInfo.expireDate)} */}
        </p>
        <p className='license-purchase'>
          <span>Purchase Date : </span>
          {dateFormat(licenseInfo.purchaseDate, 'yyyy-MM-dd')}
        </p>
        <p className='license-expire'>
          <span>Expiration Date : </span>
          {dateFormat(licenseInfo.expireDate, 'yyyy-MM-dd')}
        </p>
      </div>
    </div>
  );
};

// const capitalizeFirstLetter = (str: string) => {
//   if (str) {
//     return str.charAt(0).toUpperCase() + str.slice(1);
//   }
// };

// const expireCompare = (expireDate: Date) => {
//   const currentDate = new Date();
//   currentDate.setHours(currentDate.getHours() + 9);

//   if (currentDate >= expireDate) {
//     return (
//       <span>
//         (이)가 <span className='license-expired'>만료</span>되었습니다.
//       </span>
//     );
//   } else {
//     return (
//       <span>
//         (을)를 <span className='license-in-use'>사용중</span>입니다.
//       </span>
//     );
//   }
// };

export default MyLicenseInfo;
