import useSWR from 'swr';
import { AuthApi } from '../../lib/api';

export default function useAuthRequest(request, { initialData, ...config } = {}) {
  return useSWR(request && JSON.stringify(request), () => AuthApi(request).then((response) => response.data), {
    ...config,
    initialData: initialData && {
      status: 200,
      statusText: 'InitialData',
      headers: {},
      data: initialData,
    },
  });
}
