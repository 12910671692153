import React from 'react';
import './SchoolDownload.scss';
import { download } from '../../../images/Download';
import useSession from '../../../hooks/useSession';

const SchoolDownload = () => {
  const { isSession } = useSession();

  const onClickDownload = (downloadUrl: string): void => {
    if (!isSession) {
      //비로그인 상태라면
      alert('You can download it only when you are logged in.');
      window.location.href = 'https://my.vrware.us/login?hl=en';
    } else if (isSession) {
      //로그인 상태라면
      window.location.href = downloadUrl;
    }
  };

  return (
    <div className='SchoolDownload'>
      <div className='download-headline'>
        <p>GENIVRWARE Download</p>
      </div>
      <div className='school-download-wrap'>
        <div className='school-download-pc'>
          <div className='school-download-pc-button'>
            <button
              onClick={() =>
                onClickDownload(
                  'https://globepoint-common.s3.ap-northeast-2.amazonaws.com/genivrware/GENIVRWARE_setup_1.4.1.exe',
                )
              }
            >
              GENIVRWARE Download
              <img src={download} alt='download' />
            </button>
          </div>
          <p className='school-download-pc-label'>*Download for Windows PC</p>
        </div>
        <div className='school-download-hmd'>
          <div className='school-download-hmd-button'>
            <a href='https://play.google.com/store/apps/details?id=com.globepoint.genivrware_cardboard' target='_sub'>
              <button>
                GENIVRWARE CardBoard Download
                <img src={download} alt='download' />
              </button>
            </a>
          </div>
          <p className='school-download-hmd-label'>*Download for Android</p>
        </div>
      </div>
    </div>
  );
};

export default SchoolDownload;
