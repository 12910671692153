import React, { useEffect } from 'react';
import { ShareMapTemplate } from '../components/shareMap/ShareMapTemplate';
import Banner from '../components/common/Banner/Banner';
import { ShareMapSearch } from '../components/shareMap/ShareMapSearch';
import { Header } from '../components/base/Header';
import { Footer } from '../components/base/Footer';
import { ShareMapDetail } from '../components/shareMap/ShareMapDetail';
import { ShareMapList } from '../components/shareMap/ShareMapList';
import { useAtom } from 'jotai';
import { bgColorAtom } from 'lib/common/atoms';

const ShareMapPage = () => {
  const [bgColor, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    setBgColor('#e73656');
  }, [setBgColor]);

  return (
    <ShareMapTemplate header={<Header />} footer={<Footer />}>
      <Banner
        illust={'/images/shareMaps/Image1.png'}
        bannerText='SHARED MAPS'
        centerNode={<ShareMapSearch />}
        bgColor={bgColor}
      />
      <ShareMapList />
      <ShareMapDetail />
    </ShareMapTemplate>
  );
};

export default ShareMapPage;
