import React from 'react';
import { NotFoundTemplate } from '../../components/notFound/NotFoundTemplate';
import { NotFoundInfo } from '../../components/notFound/NotFoundInfo';
const NotFoundPage = () => {
  return (
    <NotFoundTemplate>
      <NotFoundInfo />
    </NotFoundTemplate>
  );
};

export default NotFoundPage;
