import React from 'react';
import './LicenseCodeUsedTitle.scss';

const LicenseCodeUsedTitle = () => {
  return (
    <div className='LicenseCodeUsedTitle'>
      <h2>License Code Usage History</h2>
    </div>
  );
};

export default LicenseCodeUsedTitle;
