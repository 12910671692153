import React from 'react';
import './ShareMapItem.scss';
import { ShareMapInfo } from 'types';
import { on } from 'hooks/shareMap/useShareMapDetail';

type ShareMapItemProps = {
  shareMapInfo: ShareMapInfo;
};

const ShareMapItem = ({ shareMapInfo }: ShareMapItemProps) => {
  const styles = {
    backgroundImage: `url(${_cutEx(shareMapInfo.imgThumbUrl)})`,
  };

  const onClickShareMapDetail = () => {
    on(shareMapInfo);
  };

  return (
    <div className='ShareMapItem' onClick={onClickShareMapDetail}>
      <div className='share-map-thumb' style={styles} />
      <div className='share-map-info'>
        <p className='share-map-title'>{shareMapInfo.title}</p>
      </div>
    </div>
  );
};

const _cutEx = (path: string) => {
  var pathTxt = path,
    exText = '';

  var dirCutNo = /\//.test(path) ? path.lastIndexOf('/') : 0;
  if (/\./.test(path.slice(dirCutNo))) {
    pathTxt = path.slice(0, path.lastIndexOf('.'));
    exText = path.slice(path.lastIndexOf('.'));
  }
  return pathTxt + '_288x162' + exText;
};

export default ShareMapItem;
