import React from 'react';

type NotFoundTemplateProps = {
  children: React.ReactNode;
};

const NotFoundTemplate = ({ children }: NotFoundTemplateProps) => {
  return (
    <div className='NotFoundTemplate'>
      <main>{children}</main>
    </div>
  );
};

export default NotFoundTemplate;
