import useSWR, { mutate } from 'swr';
import { ShareMapInfo } from 'types';

const KEY = 'SHARE_MAP_DETAIL';

export default function useShareMapDetail() {
  const { data: shareMapDetail } = useSWR<ShareMapInfo>(KEY);
  const { data: isShow = false, mutate: setIsShow } = useSWR<boolean>(KEY + '_isShow');

  const off = () => {
    setIsShow(false);
  };

  return {
    shareMapDetail,
    isShow,
    off,
  };
}

export const on = (_shareMapDetail: ShareMapInfo) => {
  mutate(KEY, _shareMapDetail);
  mutate(KEY + '_isShow', true);
};
