import React from 'react';
import './LicenseCodeUsedTerm.scss';

const licenseCodeTermList = [
  { value: 3, text: '3 Months' },
  { value: 6, text: '6 Months' },
  { value: 12, text: '1 Years' },
];

type LicenseCodeUsedTermProps = {
  lookupPeriod: number;
  onClickLookupPeriod(lookupPeriod: number): void;
};

const LicenseCodeUsedTerm = ({ lookupPeriod, onClickLookupPeriod }: LicenseCodeUsedTermProps) => {
  return (
    <div className='LicenseCodeUsedTerm'>
      <div className='license-code-used-term-wrap'>
        <div className='license-code-term-left'>Inquiry Period</div>
        <div className='license-code-term-right'>
          {licenseCodeTermList.map((licenseCodeTerm) => (
            <div
              className={licenseCodeTerm.value === lookupPeriod ? `license-code-term term-active` : `license-code-term`}
              key={licenseCodeTerm.value}
              onClick={() => onClickLookupPeriod(licenseCodeTerm.value)}
            >
              {licenseCodeTerm.text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LicenseCodeUsedTerm;
