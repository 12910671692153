import React from 'react';
import './DownloadTemplate.scss';

type DownloadTemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

const DownloadTemplate = ({ header, children, footer }: DownloadTemplateProps) => {
  return (
    <div className='DownloadTemplate'>
      {header}
      <main>{children}</main>
      {footer}
    </div>
  );
};

export default DownloadTemplate;
