import React from 'react';
import { MyPageLoading } from '../MyPageLoading';
import { MyLicenseInfo } from '../MyLicenseInfo';
import './MyLicenseList.scss';
import { NoLicenseList } from '../NoLicenseList';

import { LicenseInfo } from 'types';
type MyLicenseListProps = {
  email?: string;
  showList: string;
  licenseList: LicenseInfo[];
  isLoading: boolean;
};

const MyLicenseList = ({ email, showList, licenseList, isLoading }: MyLicenseListProps) => {
  if (isLoading) return <MyPageLoading loading={isLoading} />;
  if (showList !== 'license') return null;
  if (licenseList.length === 0) return <NoLicenseList />;
  return (
    <div className='MyLicenseList'>
      {licenseList.map((licenseInfo) => (
        <MyLicenseInfo key={licenseInfo.email} licenseInfo={licenseInfo} />
      ))}
    </div>
  );
};

export default MyLicenseList;
