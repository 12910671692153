import React from 'react';
import './Footer.scss';
import { footer_logo } from 'images/Footer';

const Footer = () => {
  return (
    <div className='footer-wrap'>
      <div className='footer-box'>
        <div className='gp-logo'>
          <img src={footer_logo} alt='globepoint' />
        </div>
        <div className='footer-center'>
          <div className='footer-center-text'>
            <div className='footer-center-top'>
              <a href='https://my.vrware.us/policy/privacy'>
                <p>Privacy Policy</p>
              </a>
              <p>|</p>
              <a href='https://my.vrware.us/policy/service'>
                <p>Terms of Use</p>
              </a>
            </div>
            <div className='footer-center-bottom'>
              <div>
                <p>Globepoint Inc.</p>
                <p>CEO : Raymond Cho</p>
              </div>
              <p>
                11F #1111, Gwangyang Frontier Valley 6, 83 Samwon-ro, Deokyang-gu, Goyang-si, Gyeonggi-do, Republic of
                Korea (Zip 10550)
              </p>
              <div>
                <p>+82 31 911 0609</p>
                <p>gpsales@globepoint.co.kr</p>
              </div>
              <p className='copyrights'>Copyrights ⓒ 2022 Globepoint. All rights reserved.</p>
            </div>
          </div>
        </div>
        <ul className='footer-right'>
          <li className='footer-cs'>
            <p>Customer Support</p>
          </li>
          <li className='footer-company-ph'>
            <p>+82 31 911 0609</p>
          </li>
          <li className='footer-company-wh'>
            <p>Weekday 10:00~18:00 (Lunch Time 12:30~13:30)</p>
            <p>Close : Holidays and Observances</p>
          </li>
          <li className='footer-company-sns'>
            <FooterSNS />
          </li>
        </ul>
      </div>
      <FooterBottomSNS />
    </div>
  );
};

const FooterSNS = () => {
  return (
    <ul>
      <li>
        <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
          <img src={'/images/footer/Icon_1.png'} alt='youtube' />
        </a>
      </li>
      <li>
        <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
          <img src={'/images/footer/Icon_2.png'} alt='facebook' />
        </a>
      </li>
      <li>
        <a href='https://twitter.com/GlobepointK' target='_sub'>
          <img src={'/images/footer/Icon_3.png'} alt='twitter' />
        </a>
      </li>
      <li>
        <a href='http://blog.vrware.co.kr/' target='_sub'>
          <img src={'/images/footer/Icon_4.png'} alt='blog' />
        </a>
      </li>
    </ul>
  );
};

const FooterBottomSNS = () => {
  return (
    <div className='footer-bottom-sns'>
      <ul>
        <li>
          <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
            <img src={'/images/footer/Icon_1.png'} alt='youtube' />
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
            <img src={'/images/footer/Icon_2.png'} alt='facebook' />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/GlobepointK' target='_sub'>
            <img src={'/images/footer/Icon_3.png'} alt='twitter' />
          </a>
        </li>
        <li>
          <a href='http://blog.vrware.co.kr/' target='_sub'>
            <img src={'/images/footer/Icon_4.png'} alt='blog' />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
