import React from 'react';
import './NoShareMap.scss';
import { no_map } from 'images/ShareMap';

const NoShareMap = ({ searchQuery }: { searchQuery: string }) => {
  return (
    <div className='NoShareMap'>
      <img src={no_map} alt='no_share_map' />
      <div>
        <label>
          There are no search results for <span>'{searchQuery}'</span>
        </label>
        <p>Please check if it is the correct search term and search again.</p>
      </div>
    </div>
  );
};

export default NoShareMap;
