import React, { useState } from 'react';
import './ShareMapSearch.scss';
import { InputSearchBox } from 'components/common/InputSearchBox';
import { SelectSearchBox } from 'components/common/SelectSearchBox';
import { useHistory } from 'react-router-dom';

const ShareMapSearch = () => {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [option, setOption] = useState('title');

  const onChangeSearchOption = (searchOption: string) => {
    switch (searchOption) {
      case '제목':
        setOption('title');
        break;
      case '닉네임':
        setOption('nickname');
        break;
      default:
        break;
    }
  };

  const onChangeSearchQuery = (_searchQuery: string) => {
    setQuery(_searchQuery);
  };

  const onClickSearch = async () => {
    if (!query) {
      // loadmap
    }
    history.push(`/community/share-map?page=1&query=${query}&option=${option}`);
  };

  const onKeyPressEnterSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClickSearch();
    }
  };

  return (
    <div className='banner-search'>
      <SelectSearchBox options={searchSelectOptions} handleChangeSearchOption={onChangeSearchOption} />
      <InputSearchBox
        placeholder='Let’s see the Shared Maps!'
        handleChangeSearchQuery={onChangeSearchQuery}
        handleClickSearch={onClickSearch}
        handleKeyPressEnterSearch={onKeyPressEnterSearch}
      />
    </div>
  );
};

const searchSelectOptions = [{ key: '1', option: 'Title' }];

export default ShareMapSearch;
