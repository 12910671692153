import React from 'react';
import './HeaderLogin.scss';
import { useHistory } from 'react-router-dom';

type HeaderLoginProps = {
  onClickLogout(): Promise<void>;
};

const HeaderLogin = ({ onClickLogout }: HeaderLoginProps) => {
  const history = useHistory();

  const onClickMypage = () => {
    const { pathname } = history.location;
    if (pathname === '/mypage') return;

    history.push('/mypage');
  };
  return (
    <div className='HeaderLogin'>
      <li onClick={onClickMypage}>
        <button className='login-button'>MYPAGE</button>
      </li>
      <li onClick={onClickLogout}>
        <button className='login-button'>LOGOUT</button>
      </li>
    </div>
  );
};

export default HeaderLogin;
