import React from 'react';
import './IntroContents.scss';

type content = {
  title: string;
  desc: string;
  url: string;
  reverse?: boolean;
};
const contentList: content[] = [
  {
    title: 'Creating a 3D VR Map',
    desc: 'Imagination is borderless. Use the drag and drop feature and make out-of-the-box VR map ideas with various 3D images.',
    url: '/images/intro/contents/Creating a 3D VR Map.PNG',
  },
  {
    title: '8 Types of Interactive Activities',
    desc: 'Immersive VR experience is a must, but it should be engaging at the same time. Infuse interactive activities such as quizzes on your map.',
    url: '/images/intro/contents/8 Types of Interactive Activities.PNG',
  },
  {
    title: 'VR Map Exploration',
    desc: 'Bring your creation to life by exploring your VR map. Experience it in a more immersive way using your HMD or cardboard HMD.',
    url: '/images/intro/contents/VR Map Exploration.png',
  },

  {
    title: 'VR Block Coding (Microbit & Scratch)',
    desc: 'Provides connection to other coding platforms including Micro: bit and Scratch, offering a wide coding knowledge for the users.',
    url: '/images/intro/contents/VR Block Coding (Microbit & Scratch).PNG',
  },
  {
    title: '3D Builder',
    desc: 'Whilst several 3D images are available inside the software, users will be able to build, customize, and add their very own 3D images to the map as well.',
    url: '/images/intro/contents/3D Builder.jpg',
  },

  {
    title: 'Interactive Metaverse Exploration',
    desc: 'Invite peers into your created metaverse and engage in various activities such as chatting, answering quizzes, or even watching a video.',
    url: '/images/intro/contents/Interactive Metaverse Exploration.PNG',
  },

  {
    title: 'Distance Learning in a Metaverse',
    desc: 'Make distance learning less boring by incorporating interactive activities into different meta classrooms.',
    url: '/images/intro/contents/Distance Learning in a Metaverse.png',
  },
  {
    title: 'VRWARE Cardboard',
    desc: 'What’s a VR without an HMD? GENIVRWARE provides its users a Do-It-Yourself Cardboard HMD to explore their created VR maps.',
    url: '/images/intro/contents/VRWARE Cardboard.jpg',
  },
  {
    title: 'Genibot Coding',
    desc: 'Genibot provides an all-in-one function coding. With its compatibility with almost all devices, coding is made easier and fun.',
    url: '/images/intro/contents/Genibot Coding.png',
  },
  {
    title: 'Devices for Exploring VR Map',
    desc: '',
    url: '/images/intro/contents/Devices for Exploring VR Map.png',
    reverse: true,
  },
];

export const IntroContents = () => {
  return (
    <div className='IntroContents'>
      {contentList.map((content: content) => (
        <div
          className='intro-content-box'
          key={content.title}
          style={content.reverse ? { flexFlow: 'column-reverse' } : {}}
        >
          <div className='content-thumb'>
            <img src={content.url} alt='content-img' />
          </div>
          <div className='content-title'>{content.title}</div>
          {content.desc && <div className='content-desc'>{content.desc}</div>}
        </div>
      ))}
    </div>
  );
};
