import React from 'react';
import './MainBanner.scss';

type MainBannerProps = {
  on(): void;
};

const MainBanner = ({ on }: MainBannerProps) => {
  return (
    <div className='MainBanner' style={{ backgroundImage: `url(${'/images/main/Image.png'})` }}>
      <div className='school-banner-content'>
        <div className='banner-text'>
          <h1 className='banner-text-title'>
            <img src={'/images/main/Title.png'} alt='Design Your Own World' />
          </h1>
          <div className='banner-watch-now-button'>
            <button onClick={on}>Watch Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
