import useSWR, { mutate } from 'swr';
import { setMemberInfo } from './useMember';
import { MemberInfo } from '../types';

const KEY = 'sessions';

export default function useSession() {
  const { data: isSession = localStorage.getItem('globe_user') ? true : false, mutate } = useSWR<boolean>(KEY);

  const login = (memberInfo: MemberInfo) => {
    mutate(true);
    if (!localStorage.getItem('globe_user')) {
      localStorage.setItem(
        'globe_user',
        JSON.stringify({ nickname: memberInfo.nickname, email: memberInfo.email, thumbnail: memberInfo.thumbnail }),
      );
    }
    setMemberInfo(memberInfo);
  };

  const logout = () => {
    mutate(false);
    if (localStorage.getItem('globe_user')) {
      localStorage.removeItem('globe_user');
    }
    setMemberInfo(undefined);
  };

  return {
    isSession,
    login,
    logout,
  };
}

export const login = (memberInfo: MemberInfo) => {
  mutate(KEY, true);
  if (!localStorage.getItem('globe_user')) {
    localStorage.setItem(
      'globe_user',
      JSON.stringify({ nickname: memberInfo.nickname, email: memberInfo.email, thumbnail: memberInfo.thumbnail }),
    );
  }
  setMemberInfo(memberInfo);
};

export const logout = () => {
  mutate(KEY, false);
  if (localStorage.getItem('globe_user')) {
    localStorage.removeItem('globe_user');
  }
  setMemberInfo(undefined);
};
