import React, { useEffect } from 'react';
import { DownloadTemplate } from '../components/download/DownloadTemplate';
import { Header } from '../components/base/Header';
import { Banner } from '../components/common/Banner';
import { Footer } from '../components/base/Footer';
import { bgColorAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';
import { ManualBox } from 'components/manual/ManualBox/ManualBox';

export const ManualPage = () => {
  const [bgColor, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    setBgColor('#e62e8b');
  }, [setBgColor]);

  return (
    <DownloadTemplate header={<Header />} footer={<Footer />}>
      <Banner illust={'/images/manual/Image1.png'} bannerText={'MANUAL'} bgColor={bgColor} />
      <div className='container'>
        <ManualBox />
      </div>
    </DownloadTemplate>
  );
};
