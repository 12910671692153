import React from 'react';
import './ManualBox.scss';
import { download } from '../../../images/Download';

export const ManualBox = () => {
  return (
    <div className='ManualBox'>
      <h2>GENIVRWARE Manual</h2>
      <div className='hrs' />
      <h3>Download the manual for the easy use of GENIVRWARE</h3>
      <div className='download-btn'>
        <div className='download-manual-button'>
          <a href='/files/manual.pdf' target='_sub'>
            <button>
              GENIVRWARE Manual Download
              <img src={download} alt='download' />
            </button>
          </a>
        </div>
        <p className='download-manual-button-label'>
          *All contents, including images, related to GENIVRWARE are copyrighted by Globepoint Inc.
        </p>
      </div>
    </div>
  );
};
