import React, { useEffect } from 'react';
import { DownloadTemplate } from '../components/download/DownloadTemplate';
import { Header } from '../components/base/Header';
import { Banner } from '../components/common/Banner';
import { Footer } from '../components/base/Footer';
import { SchoolDownload } from '../components/download/SchoolDownload';
import { SchoolDownloadSpec } from '../components/download/SchoolDownloadSpec';
import { bgColorAtom } from 'lib/common/atoms';
import { useAtom } from 'jotai';

const DownloadPage = () => {
  const [bgColor, setBgColor] = useAtom(bgColorAtom);

  useEffect(() => {
    setBgColor('#fdd000');
  }, [setBgColor]);

  return (
    <DownloadTemplate header={<Header />} footer={<Footer />}>
      <Banner illust={'/images/download/Image1.png'} bannerText={'DOWNLOAD'} bgColor={bgColor} />
      <div className='container'>
        <SchoolDownload />
        <SchoolDownloadSpec />
      </div>
    </DownloadTemplate>
  );
};

export default DownloadPage;
