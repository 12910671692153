import React, { useCallback, useEffect, useState } from 'react';
import './ShareMapList.scss';
import { ShareMapItem } from '../ShareMapItem';
import Pagination from 'rc-pagination';
import { NoShareMap } from '../NoShareMap';
import { SchoolApi } from 'lib/api';
import { useHistory } from 'react-router-dom';
import useLocationQuery from 'hooks/useLocationQuery';
import { ShareMapInfo } from 'types';

type ShareMapQuery = {
  page: number;
  query: string;
  option: string;
};

const ShareMapList = () => {
  const history = useHistory();
  const { page = 1, query = '', option = '' } = useLocationQuery<ShareMapQuery>();
  const [shareMapList, setShareMapList] = useState<ShareMapInfo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const limit = 12;
  const offset = (page - 1) * 12;

  const loadShareMaps = useCallback(async () => {
    try {
      const res = await SchoolApi.get(`/v1.1/share-maps?offset=${offset}&limit=${limit}&q=${query}&t=${option}`);
      if (res.data.status_code === 200) {
        setShareMapList(res.data.data.mapList);
        setTotalCount(res.data.data.mapListTotalCount);
      }
    } catch (error) {
      if (error.response.data.status_code >= 400) {
        setShareMapList([]);
        setTotalCount(0);
      }
    }
  }, [offset, option, query, setShareMapList]);

  useEffect(() => {
    loadShareMaps();
  }, [loadShareMaps]);

  if (shareMapList?.length === 0) return <NoShareMap searchQuery={query} />;

  const onClickPageClick = (page: number) => {
    history.push(`/community/share-map?page=${page}&query=${query}&option=${option}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className='ShareMapList'>
      {shareMapList?.map((shareMapInfo) => (
        <ShareMapItem key={shareMapInfo.map_id} shareMapInfo={shareMapInfo} />
      ))}
      <Pagination
        className='pagination'
        total={Math.ceil(totalCount / limit) * limit}
        current={Number(page)}
        onChange={onClickPageClick}
        pageSize={limit}
        prevIcon={'<'}
        nextIcon={'>'}
        jumpPrevIcon={''}
        jumpNextIcon={''}
      />
    </div>
  );
};

export default ShareMapList;
