import React, { useCallback, useState, useEffect } from 'react';
import './LicenseCodeUsedList.scss';
import { dateFormat } from '../../../../lib/common/dateFormat';
import { VrwareApi } from 'lib/api';
import { LicenseCodeInfo } from 'types';

type LicenseCodeUsedListProps = {
  email?: string;
  lookupPeriod: number;
};

const LicenseCodeUsedList = ({ email, lookupPeriod }: LicenseCodeUsedListProps) => {
  const [licenseCodeUsedList, setLicenseCodeUsedList] = useState<LicenseCodeInfo[]>([]);
  const loadLicenseCodeList = useCallback(async () => {
    const res = await VrwareApi.get(`/v1.0/license-codes?email=${email}&lookupPeriod=${lookupPeriod}`);
    setLicenseCodeUsedList(res.data.data.licenseCodeList);
  }, [email, lookupPeriod]);

  useEffect(() => {
    loadLicenseCodeList();
  }, [loadLicenseCodeList]);

  if (licenseCodeUsedList.length === 0) return <div>No history of code usage</div>;
  return (
    <div className='LicenseCodeUsedList'>
      <table>
        <thead>
          <tr>
            <td>라이선스 코드</td>
            <td>라이선스 등급</td>
            <td>라이선스 시작일</td>
            <td>라이선스 만료일</td>
            <td>코드 사용 인원/코드 제한 인원</td>
          </tr>
        </thead>
        <tbody>
          {licenseCodeUsedList.map((codeInfo) => {
            const codeUserInfo = codeInfo.codeUsers.find((users) => users.email === email);
            if (!codeUserInfo) return null;
            const startDate = new Date(codeUserInfo.useDate);
            const endDate = new Date(codeUserInfo.useDate);
            const expireDate = endDate.setDate(endDate.getDate() + codeInfo.licenseDate);
            return (
              <tr key={codeInfo.licenseCode}>
                <td>{codeInfo.licenseCode}</td>
                <td>{codeInfo.licenseGrade}</td>
                <td>{dateFormat(startDate, 'yyyy.MM.dd')}</td>
                <td>{dateFormat(expireDate, 'yyyy.MM.dd')}</td>
                <td>{`${codeInfo.codeUsers.length}/${codeInfo.usageLimit}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LicenseCodeUsedList;
