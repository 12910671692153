import React from 'react';
import './IntroTop.scss';

export const IntroTop = () => {
  return (
    <div className='IntroTop'>
      <h2 className='intro-top-header'>The Easiest and Quickest Way of Creating Your Own Meta World!</h2>

      <div className='intro-top-box'>
        <div className='intro-top-left'>
          <p>GENIVRWARE Package Inclusions</p>
          <div>License Code (1year) + DIY Cardboard</div>
        </div>
        <div className='intro-top-right'>
          <img src={'/images/intro/Image_1.png'} alt='img' />
        </div>
      </div>

      <div className='intro-desc'>
        <h3>“ Metaverse Learning Platform for the Creative Minds! ”</h3>
        <div>
          GENIVRWARE is a VR-based metaverse making solution that allows users to build, create, and design their own
          virtual metaverses. Users will then explore their creative contents through a cardboard HMD or other available
          head-mounted devices. Using the function tools and features of the software, young learners will be able to
          express and visualize their ideas, which are necessary for the development of their visual skills, design
          skills, and AI thinking skills.
        </div>
      </div>
    </div>
  );
};
